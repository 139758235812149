/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'shop': {
    width: 27,
    height: 32,
    viewBox: '0 0 27 32',
    data: '<path pid="0" d="M1 31l-.75-.036-.037.786H1V31zm1-21v-.75h-.715l-.034.714L2 10zm23 0l.75-.036-.035-.714H25V10zm1 21v.75h.787l-.038-.786L26 31zM8 7h.75v-.023l-.001-.023L8 7zm11 0l-.749-.046-.001.023V7H19zM1.75 31.036l1-21-1.5-.072-1 21 1.5.072zm22.5-21l1 21 1.5-.072-1-21-1.5.072zM26 30.25H1v1.5h25v-1.5zM8 7l.749-.045a1.002 1.002 0 01-.002-.044 6.657 6.657 0 01.033-.773 6.4 6.4 0 01.458-1.843c.28-.656.709-1.282 1.361-1.746.647-.46 1.568-.799 2.901-.799V.25c-1.601 0-2.836.412-3.77 1.076-.93.661-1.51 1.535-1.87 2.38-.36.84-.51 1.67-.572 2.28a8.155 8.155 0 00-.038 1.031v.019l.001.006v.003L8 7zm5.5-5.25c1.333 0 2.254.338 2.9.799.653.464 1.081 1.09 1.362 1.746.281.659.406 1.33.458 1.843a6.633 6.633 0 01.032.81v.007L19 7l.749.045v-.003-.006a1.849 1.849 0 01.003-.082 8.15 8.15 0 00-.04-.968 7.897 7.897 0 00-.571-2.28c-.361-.845-.941-1.719-1.87-2.38C16.335.662 15.1.25 13.5.25v1.5zm8.5 9h3v-1.5h-3v1.5zm-20 0h3v-1.5H2v1.5zM7.25 7v7h1.5V7h-1.5zm11 0v7h1.5V7h-1.5zM11 10.75h5v-1.5h-5v1.5z" _fill="#fff"/>'
  }
})
