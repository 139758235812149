/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-next': {
    width: 68,
    height: 14,
    viewBox: '0 0 68 14',
    data: '<path pid="0" d="M1.5 6h-1v2h1V6zm64 2h1V6h-1v2zm-3.293-6.707L61.5.586 60.086 2l.707.707 1.414-1.414zM66.5 7l.707.707a1 1 0 000-1.414L66.5 7zm-5.707 4.293l-.707.707 1.414 1.414.707-.707-1.414-1.414zM1.5 8h64V6h-64v2zm59.293-5.293l5 5 1.414-1.414-5-5-1.414 1.414zm5 3.586l-5 5 1.414 1.414 5-5-1.414-1.414z" _fill="#C09C5E"/>'
  }
})
