/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.77 23.58l-2.24-2a.5.5 0 010-.71l7.9-8.87L5.5 3.13a.5.5 0 010-.71l2.24-2a.5.5 0 01.71 0L18.8 12 8.48 23.54a.5.5 0 01-.71.04z" data-name="Arrow Left"/>'
  }
})
