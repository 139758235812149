/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-right2': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" _fill="#1D1D1B" d="M3.854 12l-.708-.707L8.44 6 3.146.707 3.854 0l6 6z"/>'
  }
})
