/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox-on': {
    width: 512,
    height: 512,
    viewBox: '0 0 512 512',
    data: '<path pid="0" _fill="none" _stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" d="M352 176L217.6 336 160 272"/><rect pid="1" x="64" y="64" width="384" height="384" rx="48" ry="48" _fill="none" _stroke="#000" stroke-linejoin="round" stroke-width="32"/>'
  }
})
