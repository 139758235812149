/* eslint-disable */
require('./arrow-next')
require('./arrow-prev')
require('./arrow-right')
require('./arrow-right2')
require('./checkbox-off')
require('./checkbox-on')
require('./cross')
require('./flavour-bg')
require('./logo-dark')
require('./logo')
require('./plus')
require('./shop')
require('./signature')
require('./signature2-white')
require('./signature2')
