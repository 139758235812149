/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-prev': {
    width: 68,
    height: 14,
    viewBox: '0 0 68 14',
    data: '<path pid="0" d="M66.5 8h1V6h-1v2zm-64-2h-1v2h1V6zm4.707-3.293L7.914 2 6.5.586l-.707.707 1.414 1.414zM1.5 7l-.707-.707a1 1 0 000 1.414L1.5 7zm4.293 5.707l.707.707L7.914 12l-.707-.707-1.414 1.414zM66.5 6h-64v2h64V6zM5.793 1.293l-5 5 1.414 1.414 5-5-1.414-1.414zm-5 6.414l5 5 1.414-1.414-5-5L.793 7.707z" _fill="#19191A"/>'
  }
})
