//import config from '../config'

let state = {

    response: {},	// объект ответа от БЕ в чистом виде
    session: {},	// данные текущей сессии
    common: {},			// общие для всех страниц
    page: {},			// индивидуальные для каждой страницы
    currentPage: {},	// текущая страница
    isLoading: false,
    routingLoadingId: null,

    // глобальные параметры UI
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight,
    isHeaderInversed: false,
    headerHeight: 64,
    withBurgerMenu: false,
    withBasketPopup: false,

    // главное меню
    menuItems: [
        {name: "Shop now", to: {name:"home-shop"}},
        {name: "Product", to: {name:"home-flavours"}},
        {name: "Our story", to: {name:"home-story"}},
        {name: "Co-creation", to: {name:"home-corporate"}},
        {name: "Partnership & franchise", to: {name:"home-partnership"}},
        //{name: "Souvenirs", to: {name:"category", params: {code:"souvenirs"}}},
        //{name: "Special occasion", to: {name:"home-special"}},
        //{name: "Our stores", to: {name:"home-stores"}},
        {name: "Contact", to: {name:"home-stores"}},
        //{name: "Terms of sale", to: {name:"terms"}},
    ],
};

export default state;
