/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'plus': {
    width: 74,
    height: 74,
    viewBox: '0 0 74 74',
    data: '<rect pid="0" x=".5" y=".5" width="73" height="73" rx="7.5" _stroke="#ECE1CE"/><path pid="1" _fill="#E1D0B3" d="M25 36h24v2H25z"/><path pid="2" _fill="#E1D0B3" d="M36 49V25h2v24z"/>'
  }
})
