/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'cross': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" d="M21 21L1 1m0 20L21 1 1 21z" _stroke="#E1E5EC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
